import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Modal
} from "semantic-ui-react";
import axios from "axios";
import { resetPasswordConfirmURL } from "../constants";
import { withTranslation } from 'react-i18next';
import { validatePasswords } from "../utils";

class PasswordChangeForm extends React.Component {
  state = {
    password1: "",
    password2: "",
    open: false,
    error_open: false,
    text: null,
    error: null,
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    const { password1, password2 } = this.state;
    const { uid, token } = this.props.match.params;
    const { t } = this.props;
    const validate_res = validatePasswords(password1, password2);
    if (validate_res === "ok") {
      this.setState({ loading: true });
      axios
        .post(resetPasswordConfirmURL, {
          uid: uid,
          token: token,
          new_password1: password1,
          new_password2: password2
        })
        .then(res => {
          this.props.history.push("/");
        })
        .catch(err => {
          // console.log(err);
          this.setState({
            text: err,
            error_open: true,
            loading: false
          });
        });
    } else {
      this.setState({
        text: t(validate_res),
        error_open: true
      });
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleConfirm = e => {
    this.setState({ open: false })
    this.props.history.push('/login');
  };

  render() {
    const { password1, password2, open, error_open, text, error, loading } = this.state;
    const { t } = this.props;
    console.log(this.state);
    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            {t("Reset password")}
          </Header>
          {error && <p>{error.message}</p>}
          <React.Fragment>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  onChange={this.handleChange}
                  fluid
                  value={password1}
                  name="password1"
                  icon="lock"
                  iconPosition="left"
                  placeholder={t("Password")}
                  type="password"
                />
                <Form.Input
                  onChange={this.handleChange}
                  fluid
                  value={password2}
                  name="password2"
                  icon="lock"
                  iconPosition="left"
                  placeholder={t("Confirm password")}
                  type="password"
                />

                <Button
                  primary
                  fluid
                  size="large"
                  loading={loading}
                  disabled={loading}
                >
                  {t("Reset")}
                </Button>
              </Segment>
            </Form>

            {/* <Confirm
              content={text}
              open={open}
              onConfirm={() => this.handleConfirm}
            />
            <Confirm
              content={text}
              header="Error"
              open={error_open}
              onConfirm={() => this.setState({ text: "", error_open: false })}
            /> */}

            <Modal
              open={open}
              size='small'
            >
              <Modal.Content>
                {text}
              </Modal.Content>
              <Modal.Actions>
                <Button primary content="OK" onClick={this.handleConfirm} />
              </Modal.Actions>
            </Modal>

            <Modal
              open={error_open}
              size='small'
            >
              <Modal.Header><Header color="red" as="h3">{t("Error")}</Header></Modal.Header>
              <Modal.Content>
                {text}
              </Modal.Content>
              <Modal.Actions>
                <Button primary content="OK" onClick={() => this.setState({ text: "", error_open: false })} />
              </Modal.Actions>
            </Modal>

          </React.Fragment>
        </Grid.Column>
      </Grid>
    );
  }
}
export default withTranslation('passwordreset')(PasswordChangeForm);
